import React from 'react';
import { useTranslation } from 'react-i18next';
import './WizzardBlock.scss';

import { ReactComponent as WalletIcon } from '../../img/wizzard/wallet.svg';
import { ReactComponent as TransactionIcon } from '../../img/wizzard/transaction.svg';
import { ReactComponent as ApiIcon } from '../../img/wizzard/api.svg';
import { ReactComponent as ShieldIcon } from '../../img/wizzard/shield.svg';
import { ReactComponent as FingerIcon } from '../../img/wizzard/finger.svg';
import { ReactComponent as KeyIcon } from '../../img/wizzard/key.svg';
import { ReactComponent as PhoneIcon } from '../../img/wizzard/phone.svg';
import { ReactComponent as QuestionIcon } from '../../img/wizzard/question.svg';
import { ReactComponent as CommunicationIcon } from '../../img/wizzard/communication.svg';
import { ReactComponent as FAIcon } from '../../img/wizzard/2fa.svg';
import { ReactComponent as DotsIcon } from '../../img/wizzard/dots.svg';
import { ReactComponent as CloseIcon } from "../../img/default-svg/close-black.svg";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const NumeratedItem = ({ index, item }) => {
  return (
    <div className="wizzard__item">
      <div className="wizzard__item-index">{index}</div>
      <div className="wizzard__item-content">
        <div className="wizzard__item-title">{item.title}</div>
        <div className="wizzard__item-description">{item.description}</div>
      </div>
    </div>
  );
};

const LinkItem = ({ item }) => {
  return (
    <div className="wizzard__item wizzard__item--links">
      <div className="wizzard__item-content">
        <div className="wizzard__item-header">
          <div className="wizzard__item-link">{item.icon}</div>
          <div className="wizzard__item-title">{item.title}</div>
        </div>
        <div className="wizzard__item-description">{item.description}</div>
      </div>
    </div>
  );
};

const CustomDot = ({ onClick, active }) => {
  return <div className={`wizzard__dot ${active ? 'wizzard__dot--active' : ''}`} onClick={() => onClick()}></div>;
};

const WizzardBlock = () => {
  const { t } = useTranslation();

  const cards = [
    {
      title: t('wizzard.0.title'),
      description: t('wizzard.0.description'),
      buttons: [
        {
          title: t('wizzard.0.start_now'),
          link: 'https://app.cpay.world/checkouts',
        },
        {
          title: t('wizzard.0.how_it_works'),
          link: 'https://docs.cpay.world/category/payments/',
          style: 'outline', // outline
        },
      ],
      listType: 'numbered', // numbered | links | icons
      items: [
        {
          title: t('wizzard.0.items.0.title'),
          description: t('wizzard.0.items.0.description'),
        },
        {
          title: t('wizzard.0.items.1.title'),
          description: t('wizzard.0.items.1.description'),
        },
      ],
    },

    {
      title: t('wizzard.1.title'),
      description: t('wizzard.1.description'),
      buttons: [
        {
          title: t('wizzard.1.documentation'),
          link: 'https://docs.cpay.world/category/api/',
        },
        {
          title: t('wizzard.1.book_call'),
          link: 'https://calendly.com/cpay-world/30min?back=1&month=2024-04',
          style: 'outline',
        },
      ],
      listType: 'links',
      items: [
        {
          title: t('wizzard.1.items.0.title'),
          description: t('wizzard.1.items.0.description'),
          link: '/',
          icon: <WalletIcon />,
        },
        {
          title: t('wizzard.1.items.1.title'),
          description: t('wizzard.1.items.1.description'),
          link: '/',
          icon: <TransactionIcon />,
        },
        {
          title: t('wizzard.1.items.2.title'),
          description: t('wizzard.1.items.2.description'),
          link: '/',
          icon: <ApiIcon />,
        },
      ],
    },
    /*{
      title: t('wizzard.2.title'),
      buttons: [
        {
          title: t('wizzard.2.documentation'),
          link: 'https://docs.cpay.world/changes/release-notes/release-4.4/',
        },
      ],
      listType: 'links',
      items: [
        {
          title: t('wizzard.2.items.0.title'),
          description: t('wizzard.2.items.0.description'),
          link: '/',
          icon: <ShieldIcon />,
        },
        {
          title: t('wizzard.2.items.1.title'),
          description: t('wizzard.2.items.1.description'),
          link: '/',
          icon: <FingerIcon />,
        },
        {
          title: t('wizzard.2.items.2.title'),
          description: t('wizzard.2.items.2.description'),
          link: '/',
          icon: <KeyIcon />,
        },
      ],
    },*/

    {
      title: t('wizzard.3.title'),
      description: t('wizzard.3.description'),
      buttons: [
        {
          title: t('wizzard.3.documentation'),
          link: 'https://discord.com/invite/dHbZbCUC7K',
        },
      ],
      listType: 'links',
      items: [
        {
          title: t('wizzard.3.items.0.title'),
          description: t('wizzard.3.items.0.description'),
          link: '/',
          icon: <PhoneIcon />,
        },
        {
          title: t('wizzard.3.items.1.title'),
          description: t('wizzard.3.items.1.description'),
          link: '/',
          icon: <QuestionIcon />,
        },
        {
          title: t('wizzard.3.items.2.title'),
          description: t('wizzard.3.items.2.description'),
          link: '/',
          icon: <CommunicationIcon />,
        },
      ],
    },

    {
      title: t('wizzard.4.title'),
      description: t('wizzard.4.description'),
      buttons: [
        {
          title: t('wizzard.4.documentation'),
          link: 'https://docs.cpay.world/changes/release-notes/release-4.4/',
        },
      ],
      listType: 'links',
      items: [
        {
          title: t('wizzard.4.items.0.title'),
          description: t('wizzard.4.items.0.description'),
          link: '/',
          icon: <FAIcon />,
        },
        {
          title: t('wizzard.4.items.1.title'),
          description: t('wizzard.4.items.1.description'),
          link: '/',
          icon: <DotsIcon />,
        },
        {
          title: t('wizzard.4.items.2.title'),
          description: t('wizzard.4.items.2.description'),
          link: '/',
          icon: <KeyIcon />,
        },
      ],
    }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="wizzard">
      <Carousel
        showDots={true}
        responsive={responsive}
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        transitionDuration={500}
        customDot={<CustomDot />}
        renderDotsOutside={true}
      >
        {cards.map(card => (
          <div className="wizzard__card">
            <div className="" style={{ width: '100%' }}>
              <div className="wizzard__header">
                <p className="wizzard__title">{card.title}</p>
                <div className="wizzard__buttons">
                  {card.buttons.map(button => (
                    <a
                      href={button.link}
                      target="_blank"
                      className={`wizzard__button wizzard__button--${button.style || ''}`}
                    >
                      {button.title}
                    </a>
                  ))}
                </div>
              </div>
              <p className="wizzard__description">{card?.description}</p>
            </div>
            <div className={`wizzard__content ${card.listType === 'links' ? 'wizzard__content--links' : ''}`}>
              {card.listType === 'numbered' &&
                card.items.map((item, index) => <NumeratedItem index={index + 1} item={item} />)}
              {card.listType === 'links' && card.items.map(item => <LinkItem item={item} />)}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default WizzardBlock;
