import React from 'react';
import FooterItem from '../FooterItem/FooterItem';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/resizeHook';
import SocialLinks from '../SocialLinks/SocialLinks';
import './style.scss';

const FooterLinks = ({
  FOOTER_LINKS,
  footerInfo,
  handleIconClick,
  linksAreOpened,
  prodLinks,
  companyLinks,
  supportLinks,
  personalLinks,
  partnerLinks,
  businessLinks,
  caseLinks,
  setHelpFormFlag,
  helpFormFlag,
}) => {
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();


  // TODO: new footer
  // const footerItemsData = [
  //   {
  //     name: 'products',
  //     title: t('Products'),
  //     data: prodLinks,
  //   },
  //   {
  //     name: 'company',
  //     title: t('Company'),
  //     data: companyLinks,
  //     title2: t('Become a partner'),
  //     data2: partnerLinks,
  //   },
  //   {
  //     name: 'supports',
  //     title: t('Support'),
  //     data: supportLinks,
  //     title2: t('Cases'),
  //     data2: caseLinks,
  //   },
  // ];

  const footerItemsDataMobile = [
    {
      name: 'products',
      title: t('Products'),
      data: prodLinks,
    },
    {
      name: 'company',
      title: t('Company'),
      data: companyLinks,
    },
    {
      name: 'supports',
      title: t('Support'),
      data: supportLinks,
    },

    {
      name: 'partner',
      title: t('Become a partner'),
      data: partnerLinks,
    },
    {
      name: 'cases',
      title: t('Cases'),
      data: caseLinks,
    },
  ];

  return (
    <div
      className={cn('footer-block', {
        'footer-block-mobile': currentWidth < 768,
      })}
    >
      <ul
        className={cn('footer-list', {
          desktop: currentWidth >= 1440,
        })}
      >
        {footerItemsDataMobile.map((item, index) => (
          <FooterItem
            key={index}
            item={item}
            linksAreOpened={linksAreOpened}
            handleIconClick={handleIconClick}
            setHelpFormFlag={setHelpFormFlag}
            helpFormFlag={helpFormFlag}
          />
        ))}
      </ul>
      {currentWidth >= 768 && (
        <div>
          <SocialLinks data={FOOTER_LINKS} isFromFooter />

          <div className="footer-privacy-wrapper">
            <p
              className={cn('footer-privacy-rights', {
                desktop: currentWidth >= 768,
              })}
            >
              {t('All rights reserved © 2025. CPAY.world')}
            </p>
            <div className="footer-privacy-terms">
              <a href="/docs/Privacy_Policy.pdf" download="Privacy_Policy.pdf">
                {t('Privacy Policy')}
              </a>
              <a href="/docs/Terms_of_Use.pdf" download="Terms_of_Use.pdf">
                {t('Terms of Service')}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FooterLinks;
