import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Popover, Spin } from "antd";
import { useTranslation } from 'react-i18next';
import SwapFilters from './SwapFilters';
import SwapItem from './SwapItem';
import SwapItemMobile from './SwapItemMobile';
import SwapDetailsMobile from './SwapDetailsMobile';
import SwapEmpty from './SwapEmpty';
import { LoadingOutlined } from '@ant-design/icons';
import { getSwapHistory } from '../../redux/actions/swap';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from '../../utils/resizeHook';
import './style.scss';
import { getSwapTokens } from '../../redux/actions/swap';
import { identity } from '../../utils/getIdentity';
import { compareNewTokensArrays } from '../../utils/compareNewTokensArrays';
import Button from '../Button';
import { getAdminSwapHistory } from "../../redux/actions/adminPanel/adminSwap";
import helpCircleIcon from "../../img/default-svg/help-circle.svg";

const defaultHistoryFilters = {
  search: '',
  currencyFromId: '',
  currencyToId: '',
  order: 'DESC',
  limit: 30,
};

const SwapHistory = ({
  swapHistoryData,
  merchantId,
  getSwapHistory,
  swapHistoryFetching,
  currencies,
  networkFilter,
  getSwapTokens,
  swapTokens,
  type = 'user',
  getAdminSwapHistory,
  adminSwapHistoryData,
  userData,
  permissions,
  adminSwapHistoryFetching
}) => {
  const [historyFilters, setHistoryFilters] = useState(defaultHistoryFilters);
  const [socket, setSocket] = useState(undefined);
  const [transactionDetailsData, setTransactionDetailsData] = useState(null);
  const [resetFilters, setResetFilters] = useState(null);
  const { page, pages, countItems, entities } = type === 'user' ? swapHistoryData || {} : adminSwapHistoryData || {};
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('swapTab') ?? localStorage.setItem('swapTab', 'swap')
  );
  const [currenciesForStableSwap, setCurrenciesForStableSwap] = useState([]);
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();
  const [activeProvider, setActiveProvider] = useState('CEX');

  useEffect(() => {
    setCurrenciesForStableSwap(compareNewTokensArrays(swapTokens, currencies));
  }, [currencies, swapTokens]);

  const storageValue = localStorage.getItem('swing');

  useEffect(() => {
    if (storageValue === 'swing') {
      setActiveProvider('DEX')
      getSwapHistory(merchantId, {
        ...historyFilters,
        currencyFromId: historyFilters.currencyFromId || undefined,
        currencyToId: historyFilters.currencyToId || undefined,
        swapType: 'swing',
      });
    } else {
      setActiveProvider('CEX')
      getSwapHistory(merchantId, {
        ...historyFilters,
        currencyFromId: historyFilters.currencyFromId || undefined,
        currencyToId: historyFilters.currencyToId || undefined,
        swapType: 'swapSpace',
      });
    }
  }, [storageValue])

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('swing');
    }, 2000)
  }, [])

  useEffect(() => {
    if (type !== 'admin') {
      merchantId &&
      getSwapHistory(merchantId, {
        ...historyFilters,
        currencyFromId: historyFilters.currencyFromId || undefined,
        currencyToId: historyFilters.currencyToId || undefined,
        swapType: activeProvider === 'CEX' ? 'swapSpace' : 'swing',
      });
    } else {
      getAdminSwapHistory({
        ...historyFilters,
        currencyFromId: historyFilters.currencyFromId || undefined,
        currencyToId: historyFilters.currencyToId || undefined,
        swapType: activeTab === 'swap' ? 'swapSpace' : 'allBridge',
      })
    }
  }, [merchantId, historyFilters, activeTab, type, activeProvider]);

  useEffect(() => {
    getSwapTokens();
  }, []);

  useEffect(() => {
    if (type === 'user') {
      socket && socket.disconnect();
      setSocket(undefined);
      createSocket(merchantId);
    }
  }, [merchantId, type]);

  const createSocket = merchantId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
    });
    socket.on('connect', () => {
      socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
      setSocket(socket);
    });
    socket.on('swapStatusUpdate', () => {
      if (merchantId) {
        getSwapHistory(
          merchantId,
          {
            ...historyFilters,
            currencyFromId: historyFilters.currencyFromId || undefined,
            currencyToId: historyFilters.currencyToId || undefined,
            swapType: activeProvider === 'CEX' ? 'swapSpace' : 'swing', // try to add, Vlad check pls
          },
          true
        );
      }
    });
    socket.on('disconnect', function () {});
  };

  return (
    <div className="block-for-component">
      <div className="swap-tabs">
        <div
          className={`swap-tabs--tab ${activeProvider === 'CEX' && 'swap-tabs--tab-active'}`}
          onClick={() => setActiveProvider('CEX')}
        >
          Swap CEX

          <Popover
            overlayClassName="popover__wrapper"
            placement="rightTop"
            content={
              <div className="swap-popover">
                <div>The cross-chain swap is processed through centralized exchanges. In some cases, KYC may be required.</div>
                <div className="swap-popover__block">The System Fee is 0.5% per transaction.</div>
              </div>
            }
          >
            <img className="password-strength__popover-icon" src={helpCircleIcon} alt="Help popup" />
          </Popover>
        </div>
        {type !== 'admin' && (
          <div
            className={`swap-tabs--tab ${activeProvider === "DEX" && "swap-tabs--tab-active"}`}
            onClick={() => setActiveProvider("DEX")}
          >
            Swap DEX

            <Popover
              overlayClassName="popover__wrapper"
              placement="rightTop"
              content={
                <div className="swap-popover">
                  <div>The cross-chain swap is processed through decentralized exchanges and protocols.</div>
                  <div className="swap-popover__block">The System Fee is 0% per transaction.</div>
                </div>
              }
            >
              <img className="password-strength__popover-icon" src={helpCircleIcon} alt="Help popup" />
            </Popover>
          </div>
        )}
      </div>
      <div className="swap-history">
        <div className="swap-history__header">
          <div className="swap-history__title">{t("swap.title")}</div>
          <Button style={{ padding: 0 }}>
            <Link
              className={`swap-history__new swap-history__new-${identity} ${type === "admin" && permissions && permissions?.length > 0 && !permissions[4]?.edit && "swap-history__new--disabled"}`}
              to={type === "admin" ? "/admin/swap/create" : type === "user" && activeProvider === "CEX" ? "/swap/create" : "/swap/create/swing"}
              disabled={type === "admin" && permissions && permissions?.length > 0 && !permissions[4]?.edit}
            >
              {t("swap.new")}
            </Link>
          </Button>
        </div>

        {transactionDetailsData && (
          <SwapDetailsMobile
            transactionDetailsData={transactionDetailsData}
            setTransactionDetailsData={setTransactionDetailsData}
            currencies={currencies}
            networkFilter={networkFilter}
          />
        )}

        <SwapFilters
          transactionDetailsData={transactionDetailsData}
          currencies={activeTab === "stable" ? currenciesForStableSwap : currencies}
          historyFilters={historyFilters}
          setHistoryFilters={setHistoryFilters}
          defaultHistoryFilters={defaultHistoryFilters}
          setResetFilters={setResetFilters}
          countItems={countItems}
          swapHistoryFetching={type !== 'admin' ? swapHistoryFetching : adminSwapHistoryFetching}
        />

        {(swapHistoryFetching || adminSwapHistoryFetching) ? (
          <div className="swap-history__loader">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <span>{t('swap.loading')}</span>
          </div>
        ) : entities && entities.length ? (
          <>
            <div className="swap-history__legend">
              <div className="swap-history__legend-exchangeId">{t('swap.exchangeId')}</div>
              <div className="swap-history__legend-date">{t('swap.date')}</div>
              <div className="swap-history__legend-currency">{t('currency')}</div>
              <div className="swap-history__legend-status">{t('status')}</div>
            </div>

            <div id="swap-scroll" className="swap-history__list">
              <div className="swap-history__list-scroller">
                <InfiniteScroll
                  dataLength={entities.length}
                  next={() =>
                    getSwapHistory(merchantId, {
                      ...historyFilters,
                      currencyFromId: historyFilters.currencyFromId || undefined,
                      currencyToId: historyFilters.currencyToId || undefined,
                      page: page + 1,
                    })
                  }
                  hasMore={entities.length < countItems && page < pages}
                  loader={
                    <div className="swap-history__loader">
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                      <span>{t('swap.loading')}</span>
                    </div>
                  }
                  scrollableTarget="swap-scroll"
                >
                  {entities.map(swapItem => {
                    return currentWidth > 590 ? (
                      <SwapItem
                        key={swapItem._id}
                        swapItem={swapItem}
                        currencies={currencies}
                        networkFilter={networkFilter}
                      />
                    ) : (
                      <SwapItemMobile
                        key={swapItem._id}
                        swapItem={swapItem}
                        setTransactionDetailsData={setTransactionDetailsData}
                        currencies={currencies}
                      />
                    );
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </>
        ) : (
          <SwapEmpty
            userData={userData}
            type={type}
            permissions={permissions}
            resetFilters={resetFilters}
            historyFilters={historyFilters}
            defaultHistoryFilters={defaultHistoryFilters}
            activeProvider={activeProvider}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  swapHistoryData: state.swap.history.data,
  swapHistoryFetching: state.swap.history.fetching,
  merchantId: state.transactions.merchantId,
  currencies: state.currencies.data,
  networkFilter: state.networkFilter,
  swapTokens: state.swap.tokens.tokens,
  adminSwapHistoryData: state.adminSwap.history.data,
  adminSwapHistoryFetching: state.adminSwap.history.fetching,
  userData: state.user.data,
  permissions: state.adminSettings.permissions,
});

const mapDispatchToProps = dispatch => ({
  getSwapHistory: getSwapHistory(dispatch),
  getSwapTokens: getSwapTokens(dispatch),
  getAdminSwapHistory: getAdminSwapHistory(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapHistory);
