import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TotalBalance from './TotalBalance';
import CurrencyItem from './CurrencyItem';
import emptyCpayImage from '../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../img/nfgpay-svg/empty-nfg.svg';
import emptyFinvaroImage from '../../img/finvaro/empty-finvaro.svg';
import emptyClarniumImage from '../../img/clarnium/empty-clarnium.svg';
import { Spin, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';
import { identity } from '../../utils/getIdentity';
import HomeActions from '../HomeActions/HomeActions';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage
};

const HomeCurrency = ({
  merchantBalances,
  balancesFetching,
  currencies,
  currencyId,
  setCurrencyId,
  isHideZeroBalances,
  setIsHideZeroBalances,
  balancesTotal,
  getMerchantBalances,
  merchantId,
  networkFilter,
  resetFiltersChangeCurrency,
}) => {
  const [balancesItems, setBalancesItems] = useState([]);
  const [availableCurrency, setAvailableCurrency] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const currenciesList = currencies?.filter((item) => item?.isInCheckout);
    const newCurrenciesName = currenciesList?.map((item) => item?.name);
    setAvailableCurrency(newCurrenciesName);
  }, [currencies, balancesItems]);

  useEffect(() => {
    if (merchantBalances.length) {
      const filtered = merchantBalances.filter(
        currencyBalance => !(isHideZeroBalances && currencyBalance.balance.usd === 0)
      );
      setBalancesItems(filtered);
    }
  }, [merchantBalances, isHideZeroBalances]);

  return (
    <div className="home-currencies">
      <TotalBalance
        merchantBalances={merchantBalances}
        isHideZeroBalances={isHideZeroBalances}
        setIsHideZeroBalances={setIsHideZeroBalances}
        balancesTotal={balancesTotal}
        getMerchantBalances={getMerchantBalances}
        merchantId={merchantId}
        networkFilter={networkFilter}
        balancesFetching={balancesFetching}
      />
      <HomeActions
        merchantId={merchantId}
        networkFilter={networkFilter}
        currencyId={currencyId}
        setCurrencyId={setCurrencyId}
        merchantBalances={merchantBalances}
      />
      <div className="home-currencies__content">
        <div className="home-currencies__title">
          {identity === 'cpay' ? t('homePage.transactions.transTitle') : t('homePage.transactions.transTitleForNfg')}
          <div>
            {currencyId && (
              <div
                className={`home-currencies__select-all home-currencies__select-all-${identity}`}
                onClick={() => setCurrencyId('')}
              >
                {t('homePage.transactions.showTrans')}
              </div>
            )}
            <div className="home-currencies__hide-zero">
              <Checkbox
                checked={isHideZeroBalances}
                onChange={() => {
                  localStorage.setItem('cpayHideZeroBalances', !isHideZeroBalances);
                  setIsHideZeroBalances(!isHideZeroBalances);
                }}
              >
                {t('homePage.transactions.zeroBal')}
              </Checkbox>
            </div>
          </div>
        </div>
        <div className="home-currencies__items-wrapper">
          {!balancesFetching ? (
            balancesItems.length ? (
              balancesItems.map(currencyBalance => (
                <>
                  {networkFilter === 'testnet' ? (
                    <>
                      {availableCurrency?.includes(currencyBalance?.currency?.name) && (
                        <CurrencyItem
                          currencyId={currencyId}
                          setCurrencyId={setCurrencyId}
                          key={currencyBalance.currency.id}
                          currencyBalance={currencyBalance}
                          title={currencyBalance.currency.title}
                          currencies={currencies}
                          resetFiltersChangeCurrency={resetFiltersChangeCurrency}
                        />
                      )}
                    </>
                  ) : (
                    <CurrencyItem
                      currencyId={currencyId}
                      setCurrencyId={setCurrencyId}
                      key={currencyBalance.currency.id}
                      currencyBalance={currencyBalance}
                      title={currencyBalance.currency.title}
                      currencies={currencies}
                      resetFiltersChangeCurrency={resetFiltersChangeCurrency}
                    />
                  )}
                </>
              ))
            ) : (
              <div className="withdraw-wallets__empty">
                <span className="transactions-list__empty-title">{t('homePage.transactions.title')}</span>
                <img src={emptyImage[identity]} className="transactions-list__empty-image" alt="empty" />
              </div>
            )
          ) : (
            <div className="withdraw-wallets__loader">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeCurrency;
