import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import enFlag from '../../img/default-svg/en.svg';
import esFlag from '../../img/default-svg/es.svg';
import arrowIcon from '../../img/default-svg/arrow.svg';
import './style.scss';
import { identity } from '../../utils/getIdentity';

const langs = [
  {
    alias: 'en',
    name: 'English',
    icon: enFlag,
  },
  {
    alias: 'es',
    name: 'Español',
    icon: esFlag,
  },
];

const LanguageSelector = ({ isFromSaleTokenCheckout, type }) => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  const arrowRef = useRef();

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        arrowRef.current &&
        !arrowRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className={!isFromSaleTokenCheckout ? 'langSelector' : 'langSelectorTokenCheckout'}>
      <div className={`langSelector__label langSelector__label-${identity}`}>{t('language')}</div>
      <div className="langSelector__current" onClick={toggleDropdown}>
        <img
          className="langSelector__selectedFlag"
          src={langs.find(lang => i18n.language.includes(lang.alias))?.icon}
          alt="Current language"
        />
        <img
          ref={arrowRef}
          className={`langSelector__arrow ${isOpen ? 'langSelector__arrow--open' : ''}`}
          src={arrowIcon}
          alt="Toggle dropdown"
        />
      </div>
      {isOpen && (
        <div
          className={`langSelector__dropdown ${isOpen ? 'langSelector__dropdown--open' : ''} ${type === 'edit' ? 'langSelector__dropdown--edit' : ''}`}
        >
          {langs.map((lang, index) => (
            <div
              className={`langSelector__option ${lang.alias === i18n.language ? 'langSelector__option_selected' : ''}`}
              onClick={() => {
                i18n.changeLanguage(lang.alias);
                setIsOpen(false);
              }}
              key={index}
            >
              <div className="langSelector__flag">
                <img src={lang.icon} alt={lang.name} />
              </div>
              <span className={`langSelector__name langSelector__name-${identity}`}>{lang.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
