import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import OffersFilters from './OffersFilters';
import OfferItem from './OfferItem';
import Button from '../Button';
import SwapWalletsEmpty from './SwapOffersEmpty';
import { ReactComponent as ChartIcon } from '../../img/default-svg/chart-icon.svg';
import { ReactComponent as InfoIcon } from '../../img/cpay-svg/info-circle.svg';
import cn from 'classnames';
import './style.scss';
import { identity } from '../../utils/getIdentity';
import OfferItemSwing from "./OfferItem/OfferItemSwing";

const SwapOffers = ({
  swapOffers,
  updatedOffers,
  setSwapOffersSort,
  setSwapOffersType,
  setSelectedPartner,
  setSelectedSwapFixed,
  setSelectedPartnerRateId,
  setSummaryRate,
  swapOffersFetching,
  amount,
  fromId,
  toId,
  sort,
  type,
  updateSwapOffers,
  swapOffersUpdateApply,
  swapOffersUpdating,
  showBalanceWarning,
  setDataIsFrozen,
  swapOffersSort,
  swapOffersType,
  setSwapAmountFromError,
  swapAmountFromError,
  isSwapSwing,
  routes,
  setBridge,
  setRate,
  currencies
}) => {
  const [showUpdateRatesBanner, setShowUpdateRatesBanner] = useState(false);
  const offersUpdateTimer = useRef();
  const { t } = useTranslation();

  useEffect(() => () => offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current), []);

  useEffect(() => {
    if (updatedOffers && updatedOffers.length && !isSwapSwing) {
      let hasDifference = false;

      for (let i = 0; i < updatedOffers?.length; i++) {
        if (!swapOffers[i]) {
          hasDifference = true;
          break;
        }

        const objA = {
          duration: updatedOffers[i].duration,
          fixed: updatedOffers[i].fixed,
          partner: updatedOffers[i].partner,
          toAmount: updatedOffers[i].toAmount,
        };

        const objB = {
          duration: swapOffers[i].duration,
          fixed: swapOffers[i].fixed,
          partner: swapOffers[i].partner,
          toAmount: swapOffers[i].toAmount,
        };

        if (
          objA.duration !== objB.duration ||
          objA.fixed !== objB.fixed ||
          objA.partner !== objB.partner ||
          objA.toAmount !== objB.toAmount
        ) {
          hasDifference = true;
          break;
        }
      }

      if (hasDifference) setShowUpdateRatesBanner(true);
    }
  }, [updatedOffers]);

  useEffect(() => {
    offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current);
    if (amount && !isSwapSwing) {
      setShowUpdateRatesBanner(false);
      setUpdateTimer();
    }
  }, [fromId, toId, amount, sort, type]);

  const handleExchange = (partner, fixed, rate, rateId) => {
    setSelectedPartner(partner);
    setSelectedSwapFixed(fixed);
    setSummaryRate(rate);
    setSelectedPartnerRateId(rateId);
    setDataIsFrozen(true);
    if (swapAmountFromError) {
      setSwapAmountFromError(null);
    }
  };

  const handleExchangeSwing = (bridge) => {
    setBridge(bridge)
    if (swapAmountFromError) {
      setSwapAmountFromError(null);
    }
  };

  const setUpdateTimer = () => {
    setShowUpdateRatesBanner(false);
    offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current);
    offersUpdateTimer.current = setInterval(
      () =>
        updateSwapOffers({
          fromId,
          toId,
          amount,
          sort,
          type,
        }),
      15000
    );
  };

  let firstEligibleIndex = -1;
  for (let i = 0; i < swapOffers?.length; i++) {
    if (swapOffers[i].partner !== 'easybit') {
      firstEligibleIndex = i;
      break;
    }
  }

  return (
    <>
      {showUpdateRatesBanner && (
        <div className={`swap-offers-update-banner swap-offers-update-banner-${identity}`}>
          <div className="swap-offers-update-banner__left">
            <ChartIcon className="swap-offers-update-banner__icon" />
            <div>
              <div className="swap-offers-update-banner__title">{t('swap.offers.exchange')}</div>
              <div className="swap-offers-update-banner__subtitle">{t('swap.offers.continue')}</div>
            </div>
          </div>
          <div className="swap-offers-update-banner__right">
            <Button
              className="swap-offers-update-banner__button"
              loading={swapOffersUpdating}
              disabled={swapOffersUpdating}
              onClick={() => {
                setShowUpdateRatesBanner(false);
                swapOffersUpdateApply();
              }}
            >
              {t('swap.offers.updateOffers')}
            </Button>
          </div>
        </div>
      )}

      {!showUpdateRatesBanner && swapOffersUpdating && (
        <div className="swap-offers-update-banner">
          <div className="swap-offers-update-banner__left">
            <InfoIcon className="swap-offers-update-banner__icon" />
            <div className="swap-offers-update-banner__title">
              {t('swap.offers.loader')}
              <span className="swap-offers-update-banner__dots-loader"></span>
            </div>
          </div>
        </div>
      )}

      <div className="swap-offers">
        <div className="swap-offers__header">
          <div className="swap-offers__title">{t('swap.offers.title')}</div>
          {identity === 'cpay' && !isSwapSwing && (
            <OffersFilters
              setSwapOffersSort={setSwapOffersSort}
              setSwapOffersType={setSwapOffersType}
              swapOffersUpdating={swapOffersUpdating}
              swapOffersSort={swapOffersSort}
              swapOffersType={swapOffersType}
            />
          )}
        </div>
        {!isSwapSwing && (
          <div
            className={cn('swap-offers__list', {
              'swap-offers__list_updating': swapOffersUpdating,
            })}
          >
            {!swapOffersFetching ? (
              swapOffers && swapOffers.length ? (
                swapOffers.map((offer, index) =>
                  identity === 'cpay' && offer.partner !== 'easybit' ? (
                    <OfferItem
                      key={`${offer.partner}-${index}`}
                      offer={offer}
                      index={index}
                      handleExchange={handleExchange}
                      showUpdateRatesBanner={showUpdateRatesBanner}
                      showBalanceWarning={showBalanceWarning}
                      setDataIsFrozen={setDataIsFrozen}
                      showBestLabel={index === firstEligibleIndex}
                    />
                  ) : (
                    index === firstEligibleIndex &&
                    offer.partner !== 'easybit' && (
                      <OfferItem
                        key={`${offer.partner}-${index}`}
                        offer={offer}
                        index={index}
                        handleExchange={handleExchange}
                        showUpdateRatesBanner={showUpdateRatesBanner}
                        showBalanceWarning={showBalanceWarning}
                        setDataIsFrozen={setDataIsFrozen}
                      />
                    )
                  )
                )
              ) : (
                <SwapWalletsEmpty />
              )
            ) : (
              <div className="swap-offers__loader">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                <span>{t('swap.offers.loader')}</span>
              </div>
            )}
          </div>
        )}

        {isSwapSwing && (
          <div className="swap-offers__list">
            {routes?.length && routes?.map((item, index) => (
              <OfferItemSwing showBalanceWarning={showBalanceWarning} currencies={currencies} toId={toId} setRate={setRate} key={index} offer={item} handleExchange={handleExchangeSwing} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SwapOffers;
